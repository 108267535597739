import { makeStyles } from '@material-ui/core/styles'

import { COLORS, FONTS } from 'styles'

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.background.default,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    fontFamily: FONTS.POPPINS_SEMI_BOLD,
    textTransform: 'inherit',
    marginTop: 10,
    width: 150,
  },
  label: {
    color: theme.palette.primary.main,
    fontFamily: FONTS.POPPINS_SEMI_BOLD,
    fontSize: '1.2rem',
  },
  input: {
    background: COLORS.WHITE,
    borderRadius: 10,
    fontFamily: FONTS.POPPINS_REGULAR,
    fontSize: '1rem',
    paddingLeft: 10,
    height: 50,
    width: 250,
    color: COLORS.GREY_INPUT_TEXT,
  },
  inputInner: {
    padding: '6px 0 6px',
    lineHeight: '23px',
  },
}))

export { useStyles }
