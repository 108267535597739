import { useCallback, useState } from "react";
import { StyleContextType } from "./types";
import { Colors, GetStyleResponse, Style } from "types/style";
import { useApiCall } from "hooks";
import { getStyles } from "api/style";

const useStyleContext = (): StyleContextType => {
    const setLocalStyle = (style: Style) => { localStorage.setItem('style', JSON.stringify(style)) }

    const [style, setStyle] = useState<Style | undefined>()
    const [isLoadingStyle, setIsLoadingStyle] = useState<boolean>(true)

    const [styleApi] = useApiCall<void, GetStyleResponse>(getStyles)

    const getStylesApi = async () => {
        const res = await styleApi()
        const style: Style = res.style
        const storeColors: Colors = JSON.parse(String(style.storeColors))

        return {
            id: style.id,
            storeLogoUrl: style.storeLogoUrl,
            storeColors,
        }
    }

    const fetchAndSetStyle = useCallback(async () => {
        const updatedStyle = await getStylesApi()

        setStyle(updatedStyle)
        setLocalStyle(updatedStyle)
        setIsLoadingStyle(false)

        return updatedStyle
    }, [styleApi])

    const getFromStorage = async () => {
        const storage = localStorage.getItem('style')
        if (!storage) return fetchAndSetStyle()
        const style: Style = JSON.parse(storage)
        const storeColors: Colors = style.storeColors
        return {
            ...style,
            storeColors
        }
    }

    return { style, setLocalStyle, fetchAndSetStyle, isLoadingStyle, getFromStorage }
}

export { useStyleContext }