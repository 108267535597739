const COLORS = {
  BASE: '#cdcdcd',
  ACCENT: '#27352e',
  TEXT_BASE: '#2b5e56',
  WHITE: '#fff',
  GREY_INPUT: '#dcdcdc',
  GREY_PLACEHOLDER: '#808080',
  GREY_INPUT_TEXT: '#666666',
  GREY_DARK: '#8a8a8a',
  GREY_MODAL_TEXT: '#4d4d4d',
  GREY_BACKDROP: '#a4a4a4',
  GREEN_LIGHT: '#00833e',
  RED: '#de1c24',
}

export { COLORS }
