import { Button, Container, Grid, List, Typography, useTheme } from '@material-ui/core'
import QRCode from 'qrcode.react'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { ReactComponent as ProfileLogo } from 'assets/images/profile.svg'
import { Header, snackbar } from 'components'
import { ERRORS, LOCAL_STORAGE } from 'consts'
import { useApiCall } from 'hooks'
import { COLORS, FONTS } from 'styles'

import { PrizeUser, User } from '../types'
import { createVisit } from './api'
import { Row, PosModal, RegisteredVisitModal } from './components'
import { useStyles } from './styles'
import { CreateVisitData } from './types'

interface DetailProps {
  getUserByQrCode: (code: string) => Promise<void>
  user?: User
}

const Detail: React.FC<DetailProps> = ({ user, getUserByQrCode }) => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const { code } = useParams()

  const [openPosModal, setOpenPosModal] = useState(false)
  const [openVisitModal, setOpenVisitModal] = useState(false)

  const [selectedCoupons, setSelectedCoupons] = useState<number[]>([])
  const [selectedPrizes, setSelectedPrizes] = useState<number[]>([])

  const admin = localStorage.getItem(LOCAL_STORAGE.ADMIN)
  const adminJSON = admin ? JSON.parse(admin) : undefined

  const onSelectCoupon = (id: number) => () => {
    setSelectedCoupons(
      selectedCoupons.includes(id)
        ? selectedCoupons.filter(coupon => coupon !== id)
        : selectedCoupons.concat(id),
    )
  }

  const onSelectPrize = (id: number) => () => {
    setSelectedPrizes(
      selectedPrizes.includes(id)
        ? selectedPrizes.filter(coupon => coupon !== id)
        : selectedPrizes.concat(id),
    )
  }

  const goToHome = () => history.push('/home')

  const [createVisitApi] = useApiCall<CreateVisitData, undefined>(createVisit)

  useEffect(() => {
    const getUser = async () => {
      if (!user && code) {
        try {
          await getUserByQrCode(code)
        } catch (error) {
          const message =
            error.type === ERRORS.RESOURCE_NOT_FOUND_MESSAGE
              ? ERRORS.QR_NOT_FOUND_MESSAGE
              : ERRORS.GENERIC_ERROR_MESSAGE
          snackbar.show(message)
          history.push('/home')
        }
      }
    }
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!user) return null

  const onSubmit = async () => {
    try {
      await createVisitApi({
        userId: user.id,
        coupons: selectedCoupons,
        prizes: selectedPrizes,
        adminId: adminJSON?.id,
      })

      if (selectedCoupons.length || selectedPrizes.length) {
        setOpenPosModal(true)
      } else {
        setOpenVisitModal(true)
      }
    } catch (error) {
      const customMessage = 'CUSTOM_MESSAGE:'
      if (error.message.includes(customMessage)) {
        const updatedMessage = error.message.replace(customMessage, '')
        snackbar.show(updatedMessage)
      } else if (error.message === ERRORS.VISIT_DURATION_ERROR_MESSAGE) {
        snackbar.show(ERRORS.VISIT_DURATION_ERROR_MESSAGE)
      } else {
        snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
      }
    }
  }

  const cleanPrizeTitle = (prizeUser: PrizeUser) => {
    // eslint-disable-next-line no-param-reassign
    prizeUser.prize.title = prizeUser.prize.title.replace(/(<([^>]+)>)/gi, '')
    return prizeUser
  }

  return (
    <Container className={classes.container} maxWidth="sm">
      <Header
        titleColor={theme.palette.primary.contrastText}
        titleFontFamily={FONTS.REGATTIA_BOLD}
        showRightButton={false}
      />
      <Grid className={classes.grid} container spacing={2}>
        <Grid xs={12} item container direction="column">
          <Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
            <Grid item>
              {user.profilePictureUrl ? (
                <img src={user.profilePictureUrl} className={classes.profilePicture} alt="" />
              ) : (
                <ProfileLogo width={80} height={80} />
              )}
            </Grid>
            <Grid item>
              <Grid item xs>
                <Typography className={classes.name}>{user.fullname}</Typography>
              </Grid>
            </Grid>
            <Grid item xs>
              <Typography className={classes.loyaltyLevel} align="right">
                Nivel {user.level ?? 1}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justify="center" alignContent="center">
          <Grid item>
            <QRCode value={user.qrHash} fgColor={theme.palette.primary.main} size={200} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.itemText}>Cupones activos en este QR</Typography>
          <List component="nav" disablePadding>
            {user.coupons &&
              user.coupons.map(({ coupon, id }) => (
                <Row
                  key={id}
                  iconUrl={coupon.iconUrl}
                  title={coupon.title}
                  onSelect={onSelectCoupon(id)}
                  selected={selectedCoupons.includes(id)}
                />
              ))}
            {user.prizes.map(cleanPrizeTitle).map(({ prize, id }) => (
              <Row
                key={id}
                iconUrl={prize.iconUrl}
                title={prize.title}
                onSelect={onSelectPrize(id)}
                selected={selectedPrizes.includes(id)}
              />
            ))}
          </List>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.redButton}
              disableElevation
              onClick={() => history.push('/home')}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.greenButton}
              disableElevation
              onClick={onSubmit}
            >
              {selectedCoupons.length || selectedPrizes.length ? 'Canjear' : 'Completar'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <PosModal open={openPosModal} onClose={goToHome} />
      <RegisteredVisitModal open={openVisitModal} onClose={goToHome} />
    </Container>
  )
}

export { Detail }
