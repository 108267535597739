import { createContext } from "react";
import { StyleContextType } from "./types"
import { Style } from "types/style"

const StyleContext = createContext<StyleContextType>({
    style: undefined,
    setLocalStyle: (style: Style) => { },
    fetchAndSetStyle: () => Promise.resolve(undefined),
    isLoadingStyle: true,
    getFromStorage: () => Promise.resolve(undefined)
})

export { StyleContext }