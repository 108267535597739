import { makeStyles } from '@material-ui/core/styles'

import { FONTS, COLORS } from 'styles'

const useStyles = makeStyles(theme => ({
  logo: {
    marginBottom: 15,
  },
  modalTitle: {
    color: theme.palette.primary.main,
    fontFamily: FONTS.POPPINS_BOLD,
    fontSize: '1.5rem',
    textAlign: 'center',
  },
}))

export { useStyles }
