import { AxiosRequestConfig } from 'axios'

import { ValidUserPayload } from './types'

const getUser = (payload: ValidUserPayload): AxiosRequestConfig => ({
  url: `/users/hash/${payload.qrCode}`,
  method: 'GET',
  params: { validateLastVisit: payload.validateLastVisit },
})

export { getUser }
