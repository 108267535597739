import { makeStyles } from '@material-ui/core/styles'

import { COLORS, FONTS } from 'styles'

const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  grid: {
    flex: 1,
    overflow: 'scroll',
    overflowX: 'hidden',
    padding: 25,
    marginTop: 25
  },
  profilePicture: {
    width: 80,
    height: 80,
    borderRadius: '50%',
  },
  name: {
    fontFamily: FONTS.POPPINS_BOLD,
    fontSize: '1.3rem',
    color: theme.palette.primary.main,
  },
  label: {
    fontFamily: FONTS.POPPINS_MEDIUM,
    fontSize: '0.7rem',
    color: theme.palette.primary.main,
  },
  loyaltyLevel: {
    fontFamily: FONTS.POPPINS_MEDIUM,
    fontSize: '1.7rem',
    color: theme.palette.primary.main,
  },
  itemText: {
    fontFamily: FONTS.POPPINS_SEMI_BOLD,
    fontSize: '0.8rem',
    color: COLORS.GREY_PLACEHOLDER,
  },
  redButton: {
    fontFamily: FONTS.POPPINS_SEMI_BOLD,
    backgroundColor: COLORS.RED,
    textTransform: 'capitalize',
    marginTop: 10,
    width: '100%',
  },
  greenButton: {
    fontFamily: FONTS.POPPINS_SEMI_BOLD,
    backgroundColor: COLORS.GREEN_LIGHT,
    textTransform: 'capitalize',
    marginTop: 10,
    width: '100%',
  },
  listItem: {
    paddingLeft: 5,
  },
}))

export { useStyles }
