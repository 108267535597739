import { makeStyles } from '@material-ui/core/styles'

import { COLORS, FONTS } from 'styles'

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
    height: 60,
  },
  toolbar: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  backButton: {
    padding: 0,
  },
  backIcon: {
    stroke: theme.palette.primary.contrastText,
  },
  questionIcon: {
    stroke: theme.palette.primary.contrastText,
    fill: theme.palette.primary.contrastText,
  },
  title: {
    color: theme.palette.primary.contrastText,
    fontFamily: FONTS.POPPINS_SEMI_BOLD,
    fontSize: '1rem',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    width: 400
  },
  logo: {
    maxHeight: 50,
    maxWidth: 400,
    marginBottom: 5,
  }
}))

export { useStyles }
