import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS } from 'styles'

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  label: {
    color: theme.palette.primary.main,
    fontFamily: FONTS.POPPINS_SEMI_BOLD,
    fontSize: '1.2rem',
    marginBottom: '1rem',
  },
  link: {
    color: theme.palette.primary.main,
    textDecorationLine: 'underline',
    fontFamily: FONTS.POPPINS_BOLD,
    fontSize: '0.7rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  input: {
    background: COLORS.WHITE,
    borderRadius: 5,
    fontFamily: FONTS.POPPINS_REGULAR,
    fontSize: '0.8rem',
    paddingLeft: 10,
    height: 45,
    width: '70%',
    [theme.breakpoints.up('sm')]: {
      width: '25%',
    },
    color: COLORS.GREY_INPUT_TEXT,
  },
  inputInner: {
    padding: '6px 0 6px',
    lineHeight: '23px',
  },
  button: {
    fontFamily: FONTS.POPPINS_SEMI_BOLD,
    textTransform: 'capitalize',
    marginTop: 10,
    width: 150,
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
    width: 400
  },
  logo: {
    maxHeight: 200,
    maxWidth: 400
  }
}))

export { useStyles }
