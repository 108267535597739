import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Container, Button, Grid } from '@material-ui/core'

import { ReactComponent as Logo } from 'assets/images/main-logo.svg'

import { useStyles } from './styles'
import { useStyleContext } from 'contexts/StyleContext/useStyleContext'

const Home: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  const [logoUrl, setLogoUrl] = useState('')

  const { style, getFromStorage, isLoadingStyle } = useStyleContext()

  useEffect(() => {
    const applyLogo = async () => {
      if (style) {
        setLogoUrl(style.storeLogoUrl)
      } else {
        const styleUpdate = await getFromStorage()
        if (styleUpdate) setLogoUrl(styleUpdate.storeLogoUrl)
      }
    }
    applyLogo()
  }, [isLoadingStyle])

  return (
    <Container className={classes.container} maxWidth={false}>
      {logoUrl ? (
        <div className={classes.logoContainer}>
          <img src={logoUrl} alt="Logo" className={classes.logo} />
        </div>
      ) : (
        <Logo height={200} width={400} />
      )}
      <Grid container spacing={1} direction="column" justify="center" alignItems="center">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disableElevation
            onClick={() => history.push('reader')}
          >
            Escanear código QR
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disableElevation
            onClick={() => history.push('code')}
          >
            Ingresar código
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export { Home }
