import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core/'

import { ReactComponent as Logo } from 'assets/images/main-logo.svg'
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrow-back.svg'
import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg'

import { useStyles } from './styles'
import { useStyleContext } from 'contexts/StyleContext/useStyleContext'

interface HeaderProps {
  title?: string
  titleFontFamily?: string
  titleColor?: string
  showRightButton?: boolean
}

const Header: React.FC<HeaderProps> = ({ title, titleFontFamily, titleColor, showRightButton }) => {
  const classes = useStyles()
  const history = useHistory()

  const [logoUrl, setLogoUrl] = useState('')

  const { style, getFromStorage, isLoadingStyle } = useStyleContext()

  useEffect(() => {
    const applyLogo = async () => {
      if (style) {
        setLogoUrl(style.storeLogoUrl)
      } else {
        const styleUpdate = await getFromStorage()
        if (styleUpdate) setLogoUrl(styleUpdate.storeLogoUrl)
      }
    }
    applyLogo()
  }, [isLoadingStyle])

  return (
    <>
      <div className={classes.appBar} />
      <AppBar className={classes.appBar} elevation={0} position="absolute">
        <Toolbar className={classes.toolbar}>
          <IconButton color="primary" onClick={history.goBack} className={classes.backButton}>
            <ArrowBackIcon width={30} height={30} className={classes.backIcon} />
          </IconButton>
          {/*<Typography
            variant="h6"
            className={classes.title}
            style={{ fontFamily: titleFontFamily, color: titleColor }}
          >
            {title}
          </Typography>*/}
          {logoUrl ? (
            <div className={classes.logoContainer}>
              <img src={logoUrl} alt="Logo" className={classes.logo} />
            </div>
          ) : (
            <Logo height={50} width={400} className={classes.logo} />
          )}
          <IconButton color="primary">
            {showRightButton ?? (
              <QuestionIcon width={30} height={30} className={classes.questionIcon} />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  )
}

export { Header }
