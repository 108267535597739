import { Button, Container, Grid, InputBase, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useHistory } from 'react-router'

import { Header, snackbar } from 'components'
import { ERRORS } from 'consts'

import { useStyles } from './styles'

interface EnterCodeProps {
  getUserByQrCode: (code: string, validateLastVisit: boolean) => Promise<void>
}

const EnterCode: React.FC<EnterCodeProps> = ({ getUserByQrCode }) => {
  const classes = useStyles()
  const history = useHistory()

  const [code, setCode] = useState('')

  const onChangeCode: React.ChangeEventHandler<HTMLInputElement> = e => {
    setCode(e.target.value)
  }

  const onSubmit = async () => {
    try {
      await getUserByQrCode(code, true)
      history.push(`/detail/${code}`)
    } catch (error) {
      const customMessage = 'CUSTOM_MESSAGE:'
      if (error.message.includes(customMessage)) {
        const updatedMessage = error.message.replace(customMessage, '')
        snackbar.show(updatedMessage)
      } else {
        const message =
          error.type === 'ResourceNotFound'
            ? ERRORS.QR_NOT_FOUND_MESSAGE
            : ERRORS.GENERIC_ERROR_MESSAGE
        snackbar.show(message)
      }
    }
  }

  return (
    <Container className={classes.container} maxWidth={false}>
      <Header />
      <Grid container spacing={3} direction="column" justify="center" alignItems="center">
        <Grid item>
          <Typography variant="h5" component="h2" className={classes.label}>
            Ingresar código
          </Typography>
        </Grid>
        <Grid item>
          <InputBase
            className={classes.input}
            placeholder="Código alfanumérico"
            inputProps={{
              className: classes.inputInner,
            }}
            value={code}
            onChange={onChangeCode}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disableElevation
            onClick={onSubmit}
          >
            Listo!
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export { EnterCode }
