import { makeStyles } from '@material-ui/core/styles'

import { COLORS, FONTS } from 'styles'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
  },
  button: {
    fontFamily: FONTS.POPPINS_BOLD,
    textTransform: 'inherit',
    marginTop: 10,
    width: 250,
    height: 60,
    fontSize: '1rem',
    borderRadius: 10,
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
    width: 400
  },
  logo: {
    maxHeight: 200,
    maxWidth: 400
  }
})

export { useStyles }
