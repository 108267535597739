import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import { Modal } from 'components'
import { ReactComponent as PointIcon } from 'assets/icons/check.svg'

import { useStyles } from './styles'

interface PosModalProps {
  open: boolean
  onClose: () => void
}

const RegisteredVisitModal: React.FC<PosModalProps> = ({ open, onClose }) => {
  const classes = useStyles()
  return (
    <Modal open={open} onClose={onClose}>
      <Grid container direction="column" spacing={1}>
        <Grid item container justify="center" alignContent="center">
          <PointIcon width={50} height={50} className={classes.logo} />
        </Grid>
        <Grid item>
          <Typography className={classes.modalTitle}>Listo, visita</Typography>
          <Typography className={classes.modalTitle}>registrada!</Typography>
        </Grid>
      </Grid>
    </Modal>
  )
}

export { RegisteredVisitModal }
